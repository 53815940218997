import React, { useState, useContext, useEffect} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import {useAuth} from '../Context/AuthContext'
import FirebaseContext from '../../context/firebase';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  //Grid,
  Link,
  TextField,
  Typography,
  Snackbar,
  makeStyles
} from '@material-ui/core';
// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  error: {
    textAlign: 'center'
  }
}));


const Alert =(props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {firebase} = useContext(FirebaseContext);
  const [open, setOpen] = useState(false)
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const isInvalid = password === '' || emailAddress === '';
  

  const handleLogin = async (event) => {
    event.preventDefault();
    try{
      await firebase.auth().signInWithEmailAndPassword(emailAddress, password);
      navigate('/app/dashboard')
    } catch (error){
      setEmailAddress('');
      setPassword('');
      setError(error.message);
      setOpen(true)
    }

  };

  useEffect(() => {
    document.title = 'Login - Investing Patrol'
  }, []);

//   const handleClick = () => {
//     setOpen(true)
// }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


// <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>  <MuiAlert onClose={handleClose} severity="error" MuiAlert elevation={6} variant="filled" >{error}</MuiAlert>  </Snackbar>



  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {/* {error && {error} } */}
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={() => {
              navigate('/app/dashboard', { replace: true });
            }}
          >

        <form onSubmit={handleLogin} method="POST">
          <Box mb={3}>
            <Typography
            color="textPrimary"
            variant="h2"
            >
              Sign In
            </Typography>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
                {error}
              </Alert>
             </Snackbar> 
            {/* <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
            >
              Sign in to Investing Patrol
            </Typography> */}
          </Box>
          {/* <Grid
          container
          spacing={3}
          >
            <Grid
            item
            xs={12}
            md={6}
            >
              <Button
              color="primary"
              fullWidth
              startIcon={<FacebookIcon />}
              //onClick={handleSubmit}
              size="large"
              variant="contained"
              >
                Login with Facebook
              </Button>
            </Grid>
            <Grid
            item
            xs={12}
            md={6}
            >
               <Button
              fullWidth
              startIcon={<GoogleIcon/>}
             // onClick={handleSubmit}
              size="large"
              variant="contained"
              >
                Login with Google
              </Button>
            </Grid>
          </Grid> */}
          <Box
          mt={3}
          mb={1}
          >
            <Typography
            algin="center"
            color="textSecondary"
            variant="body1"
            >
             Login with email address
            </Typography>
          </Box>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Enter your email address"
            type="text"
            placeholder="Email address"
            onChange={({target})  => setEmailAddress(target.value)}
            value={emailAddress}
            />

            <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Enter your password"
            type="password"
            placeholder="Password"
            onChange={({target})  => setPassword(target.value)}
            value={password}
            />

             
            <Box my={2}> 
              <Button
              color="primary"
              disabled={isInvalid}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleLogin}
              >
                Log in
              </Button>
            </Box>
            <Typography
            color="textSecondary"
            variant="body1"
            >
              Don&apos;t have an account? 
              {' '}
              <Link
              component={RouterLink}
              to="/register"
              variant="h6"
              >
                Sign up
              </Link>
            </Typography>
            <Typography
            color="textSecondary"
            variant="body1"
            >
             Forgot Password?
              {' '}
              <Link
              component={RouterLink}
              to="/forgot-password"
              variant="h6"
              >
               Passowrd Reset
              </Link>
            </Typography>
        </form>


           
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default Login;

// {({
//   errors,
//   handleBlur,
//   handleChange,
//   handleSubmit,
//   isSubmitting,
//   touched,
//   values
// }) => (
//   <form onSubmit={handleSubmit}>
//     <Box mb={3}>
//       <Typography
//         color="textPrimary"
//         variant="h2"
//       >
//         Sign in
//       </Typography>
//       <Typography
//         color="textSecondary"
//         gutterBottom
//         variant="body2"
//       >
//         Sign in on the internal platform
//       </Typography>
//     </Box>
//     <Grid
//       container
//       spacing={3}
//     >
//       <Grid
//         item
//         xs={12}
//         md={6}
//       >
//         <Button
//           color="primary"
//           fullWidth
//           startIcon={<FacebookIcon />}
//           onClick={handleSubmit}
//           size="large"
//           variant="contained"
//         >
//           Login with Facebook
//         </Button>
//       </Grid>
//       <Grid
//         item
//         xs={12}
//         md={6}
//       >
//         <Button
//           fullWidth
//           startIcon={<GoogleIcon />}
//           onClick={handleSubmit}
//           size="large"
//           variant="contained"
//         >
//           Login with Google
//         </Button>
//       </Grid>
//     </Grid>
//     <Box
//       mt={3}
//       mb={1}
//     >
//       <Typography
//         align="center"
//         color="textSecondary"
//         variant="body1"
//       >
//         or login with email address
//       </Typography>
//     </Box>
//     <TextField
//       error={Boolean(touched.email && errors.email)}
//       fullWidth
//       helperText={touched.email && errors.email}
//       label="Email Address"
//       margin="normal"
//       name="email"
//       onBlur={handleBlur}
//       onChange={handleChange}
//       type="email"
//       value={values.email}
//       variant="outlined"
//     />
//     <TextField
//       error={Boolean(touched.password && errors.password)}
//       fullWidth
//       helperText={touched.password && errors.password}
//       label="Password"
//       margin="normal"
//       name="password"
//       onBlur={handleBlur}
//       onChange={handleChange}
//       type="password"
//       value={values.password}
//       variant="outlined"
//     />
//     <Box my={2}>
//       <Button
//         color="primary"
//         disabled={isSubmitting}
//         fullWidth
//         size="large"
//         type="submit"
//         variant="contained"
//       >
//         Sign in now
//       </Button>
//     </Box>
//     <Typography
//       color="textSecondary"
//       variant="body1"
//     >
//       Don&apos;t have an account?
//       {' '}
//       <Link
//         component={RouterLink}
//         to="/register"
//         variant="h6"
//       >
//         Sign up
//       </Link>
//     </Typography>
//   </form>
// )}