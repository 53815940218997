import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {useContext} from 'react';
import FirebaseContext from '../../context/firebase'
import UserContext from '../../context/user';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  //Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import  {teal}  from '@material-ui/core/colors';
//import { Alert } from '@material-ui/lab';
//import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
//import InputIcon from '@material-ui/icons/Input';
// import Logo from 'src/components/Logo';
//import NewLogo from 'src/components/NewLogo';
import Patrol from 'src/components/Patrol';



const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  blue: {
    color: '#fff',
    backgroundColor: teal[500]
  },
  space: {
    marginRight: '3%'
  }
}));

// const image = {
//   avatar: '/static/images/products/product_2.png'
// }


const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  //const navigate = useNavigate();
  const { firebase } = useContext(FirebaseContext);
  const {user} = useContext(UserContext);
  
  
  return (
    
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      
      <Toolbar>
        <RouterLink to="/">
          <Patrol />
        </RouterLink>
        <Box flexGrow={1} />
       
        {user ? (
          <>
          <RouterLink
          className={classes.space}
          to="/app/dashboard">
          <Button
          color='primary' 
          variant='contained'
          onClick={() => firebase.auth().signOut()}
          onKeyDown={(event) => {
            if(event.key === 'Enter'){
              firebase.auth().signOut();
            }
          }}
          >
            Log out
            </Button>
            </RouterLink>
            {user.displayName && (
               <Avatar 
               className={classes.blue}
               component={RouterLink}
               //src={image.avatar}
               alt={`Profile Picture`}
              // src={`/images/avatars/${user.displayName}.jpg`
               to={`/app/profile/${user.displayName}`}  
               >
                {user.displayName.substring(0,1)}
               </Avatar>
            )}
          </>
        ) : (
          <>
          <RouterLink to="/login">
            <Button color='primary' variant='contained'>Log In</Button>
          </RouterLink>
          </>
        )}

        {/* <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit">
            <InputIcon />
          </IconButton>
        </Hidden> */}
      
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
