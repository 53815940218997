import React, {useState, useEffect} from "react";
import clsx from 'clsx';
import { Link } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Avatar,
  Button,
  Card,
  CardHeader,
  Checkbox,
 // Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
 // TableSortLabel,
 // Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Skeleton from 'react-loading-skeleton';
import AddCrypto from './AddCrypto';
import ProfileCryptoToolbar from './ProfileCryptoToolbar';


const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  avatar: {
    marginRight: theme.spacing(1)
  }
}));

const ProfileCrypto = ({ className, username, crypto, docId, cryptoInput, ...rest }) => {
  const classes = useStyles();
  const [userCryptos, setUserCryptos] = useState(crypto);
  const [userCryptosInfo, setUserCryptosInfo] = useState([]);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  //console.log('profileStocks', userStocks.map((poop) => poop))
 

  useEffect(() => {
    setUserCryptos(crypto)
  
  const getData = () => {
    
    fetch(
       `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${userCryptos}&order=market_cap_desc&per_page=100&page=1&sparkline=false`
     ).then((res) =>
     res.json().then((data) => {
       if(!data.errors){
        setUserCryptosInfo(data)
       } else {
        setUserCryptosInfo([])
       }
     })
     )
   }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crypto])

  
  


   const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

   if(event.target.checked){
     newSelectedCustomerIds = userCryptosInfo.map((coin) => coin.id)
   } else {
     newSelectedCustomerIds = [];
   }

   setSelectedCustomerIds(newSelectedCustomerIds);
  }


  const handleSelectOne = (event, id) => {
   const selectedIndex = selectedCustomerIds.indexOf(id);
   //console.log(symbol)
   let newSelectedCustomerIds = [];
   if (selectedIndex === -1) {
     newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
   } else if (selectedIndex === 0) {
     newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
   } else if (selectedIndex === selectedCustomerIds.length - 1) {
     newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
   } else if (selectedIndex > 0) {
     newSelectedCustomerIds = newSelectedCustomerIds.concat(
       selectedCustomerIds.slice(0, selectedIndex),
       selectedCustomerIds.slice(selectedIndex + 1)
     );
   }

   setSelectedCustomerIds(newSelectedCustomerIds);
 };


 const handleLimitChange = (event) => {
   setLimit(event.target.value);
   setPage(0)
 };

 const handlePageChange = (event, newPage) => {
   setPage(newPage);
 };


const emptyRows = limit - Math.min(limit, userCryptos?.length - page * limit);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
     {!userCryptos? (
      <Skeleton count={1} />
     ): userCryptos.length > 0 ? (
      <>
      <CardHeader title={`${username}'s Crypto`} />
          <Divider />
          <PerfectScrollbar>
            <Box minWidth={800}>
            <ProfileCryptoToolbar numSelected={selectedCustomerIds.length} docId={docId} setSelectedCustomerIds={setSelectedCustomerIds} selectedCustomerIds={selectedCustomerIds} userCryptosInfo={userCryptosInfo} setUserCryptosInfo={setUserCryptosInfo} setUserCryptos={setUserCryptos} />
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                    checked={selectedCustomerIds.length === crypto.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < crypto.length
                    }
                    onChange={handleSelectAll}
                    />
                  </TableCell>
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell>
                      Price
                    </TableCell>
                    <TableCell>
                     Changes
                    </TableCell>
                    <TableCell>
                     Volume Average
                    </TableCell>
                    <TableCell>
                      Market Cap
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                  {userCryptosInfo.length > 0 &&
                  userCryptosInfo.slice(page * limit, page * limit + limit).map((info) => (
                    <TableRow
                      hover
                      key={info.symbol}
                      selected={selectedCustomerIds.indexOf(info.id) !== -1}
                    >
                       <TableCell padding="checkbox">
                       <Checkbox
                      checked={selectedCustomerIds.indexOf(info.id) !== -1}
                      onChange={(event) => handleSelectOne(event, info.id)}
                      value="true"
                        />
                       </TableCell>
                      <TableCell>
                      <Box
                          alignItems="center"
                          display="flex"
                        >
                        <Link to={{ pathname: `/app/cryptooverview/${info.id}` }}>
                      <Avatar
                            className={classes.avatar}
                            src={info.image}
                          >
                            {info.name}
                          </Avatar>
                          </Link>
    
                          <Link to={{ pathname: `/app/cryptooverview/${info.id}` }}>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                          >
                            {info.name}
                          </Typography>
                          </Link>
    
                          </Box>
                      </TableCell>
                      <TableCell>
                       $  {info.current_price ? info.current_price.toLocaleString() : 'loading'} 
                      </TableCell>
    
    
                      <TableCell>
                       {info.price_change_24h ? info.price_change_24h.toFixed(2): 'loading'}   
                       </TableCell>
    
                       <TableCell>
                         {info.total_volume ? info.total_volume.toLocaleString() : 'loading'} 
                       </TableCell>
                       
                       <TableCell>
                          $ {info.market_cap ? info.market_cap.toLocaleString() : 'loading'}
                       </TableCell>
                       </TableRow>
                   ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 73 * emptyRows}}>
                  <TableCell colSpan={3} />
                  
                </TableRow>
              )}

                 </TableBody>
               </Table>
             </Box>
           </PerfectScrollbar>
           <TablePagination 
           component="div"
           count={userCryptos.length}
           onChangePage={handlePageChange}
           onChangeRowsPerPage={handleLimitChange}
           page={page}
           rowsPerPage={limit}
           rowsPerPageOptions={[5, 10, 25]}
           />

           <Box
             display="flex"
             justifyContent="flex-end"
             p={2}
           >
             <Button
               color="primary"
               endIcon={<ArrowRightIcon />}
               size="small"
               variant="text"
               href="/app/crypto"
               
             >
               View all Crypto Coins
             </Button>
           </Box>
         
          </>
      ) :(
      <p>Your not following any Crypto Coins</p>
     )}
    

    <AddCrypto 
    docId={docId}
    userCryptos={userCryptos}
    setUserCryptos={setUserCryptos}
    userCryptosInfo={userCryptosInfo}
    setUserCryptosInfo={setUserCryptosInfo}
    cryptoInput={cryptoInput}
    />

    </Card>
  
  
  )
  
};


export default ProfileCrypto;