import React, {useState, useEffect} from "react";
import clsx from 'clsx';
import { Link } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Avatar,
  Button,
  Card,
  CardHeader,
  Checkbox,
 // Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
 // TableSortLabel,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Skeleton from 'react-loading-skeleton';
import AddStock from './AddStock';
import ProfileStockToolbar from './ProfileStockToolbar';

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  avatar: {
    marginRight: theme.spacing(1)
  }
}));

const ProfileStocks = ({ className,  username, stocks, docId, commentInput, ...rest }) => {
  const classes = useStyles();
  const [userStocks, setUserStocks] = useState(stocks);
  const [userStocksInfo, setUserStocksInfo] = useState([]);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  //console.log('profileStocks', userStocksInfo.map((poop) => poop))
 
  useEffect(() => {
    setUserStocks(stocks)
    const getData = () => {
      fetch(
         `https://financialmodelingprep.com/api/v3/profile/${userStocks}?apikey=${process.env.REACT_APP_FKEY}`
       ).then((res) =>
       res.json().then((data) => {
         if(!data.errors){
           setUserStocksInfo(data)
         } else {
           setUserStocksInfo([])
         }
       })
       )
     }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stocks])

  
  


   const handleSelectAll = (event) => {
     let newSelectedCustomerIds;

    if(event.target.checked){
      newSelectedCustomerIds = userStocksInfo.map((stock) => stock.symbol)
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
   }


   const handleSelectOne = (event, symbol) => {
    const selectedIndex = selectedCustomerIds.indexOf(symbol);
    //console.log(symbol)
    let newSelectedCustomerIds = [];
    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, symbol);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };


  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0)
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  



  const emptyRows = limit - Math.min(limit, userStocks?.length - page * limit);
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
// pass in username

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
     {!userStocks ? (
      <Skeleton count={1} />
     ): userStocks.length > 0 ? (
      <>
      <CardHeader title={`${username}'s Stocks`} />
          <Divider />
          <PerfectScrollbar>
            <Box minWidth={800}>
            <ProfileStockToolbar numSelected={selectedCustomerIds.length}  docId={docId} setSelectedCustomerIds={setSelectedCustomerIds} selectedCustomerIds={selectedCustomerIds} userStocksInfo={userStocksInfo} setUserStocksInfo={setUserStocksInfo} setUserStocks={setUserStocks}  />
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === stocks.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < stocks.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>

                    <TableCell>
                     Company Name
                    </TableCell>
                    <TableCell>
                      Price
                    </TableCell>
                    <TableCell>
                     Changes
                    </TableCell>
                    <TableCell>
                     Volume Average
                    </TableCell>
                    <TableCell>
                      Market Cap
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                  {userStocksInfo.length > 0 &&
                  userStocksInfo.slice(page * limit, page * limit + limit).map((info) => (
                    <TableRow
                      hover
                      key={info.symbol}
                      selected={selectedCustomerIds.indexOf(info.symbol) !== -1}
                    >
                      <TableCell padding="checkbox">
                      <Checkbox
                      checked={selectedCustomerIds.indexOf(info.symbol) !== -1}
                      onChange={(event) => handleSelectOne(event, info.symbol)}
                      value="true"
                        />
                      </TableCell>
                      <TableCell>
                      <Box
                          alignItems="center"
                          display="flex"
                        >
                        <Link to={{ pathname: `/app/info2/${info.symbol}` }}>
                      <Avatar
                            className={classes.avatar}
                            src={info.image}
                          >
                            {info.name}
                          </Avatar>
                          </Link>
    
                          <Link to={{ pathname: `/app/info2/${info.symbol}` }}>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                          >
                            {info.companyName}
                          </Typography>
                          </Link>
    
                          </Box>
                      </TableCell>
                      <TableCell>
                      $  {info.price.toLocaleString()}
                      </TableCell>
    
                      <TableCell>
                       {info.changes}       
                       </TableCell>
    
                       <TableCell>
                         $ {info.volAvg.toLocaleString()}
                       </TableCell>
                       
                       <TableCell>
                         $ {info.mktCap.toLocaleString()}
                       </TableCell>
                       </TableRow>
                   ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 73 * emptyRows}}>
                  <TableCell colSpan={3} />
                  
                </TableRow>
              )}

                 </TableBody>
               </Table>
             </Box>
           </PerfectScrollbar>
           <TablePagination 
           component="div"
           count={userStocks.length}
           onChangePage={handlePageChange}
           onChangeRowsPerPage={handleLimitChange}
           page={page}
           rowsPerPage={limit}
           rowsPerPageOptions={[5, 10, 25]}
           
           />

           <Box
             display="flex"
             justifyContent="flex-end"
             p={2}
           >
             <Button
               color="primary"
               endIcon={<ArrowRightIcon />}
               size="small"
               variant="text"
               href="/app/stocks"
               
             >
               View all Stocks
             </Button>

           </Box>

         
          </>
      ) :(
      <p>Your not following any stocks</p>
     )}
    
      <AddStock 
            docId={docId} 
            userStocks={userStocks} 
            setUserStocks={setUserStocks} 
            userStocksInfo={userStocksInfo}
            setUserStocksInfo={setUserStocksInfo}
            commentInput={commentInput} 
    
            />

    </Card>
  
  
  )
  
};


export default ProfileStocks;


