import React, {lazy, Suspense} from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
// import AccountView from 'src/views/account/AccountView';
import ProfileView from 'src/views/Profile';
//import CustomerListView from 'src/views/customer/CustomerListView';
//import LoginView from 'src/views/auth/LoginView';


import PrivateRoute from './PrivateRoute'

import IsUserLoggedIn from 'src/helper/IsUserLoggedIn'


const Login = lazy(() => import ('src/views/SignIn/Login'));
const Register  = lazy(() => import ('src/views/SignIn/Register'))
const StocksListView = lazy(() => import('src/views/StocksPage'))
const CryptoPage = lazy(() => import('src/views/CryptoPage'))
//import CryptoPage from 'src/views/CryptoPage'
const ETFListView = lazy(() => import('src/views/ETFPage')) 
// import ETFListView from 'src/views/ETFPage';
const SearchPage = lazy(() => import('src/views/SearchPage'))
//import SearchPage from 'src/views/SearchPage';
const MarketsPage = lazy(() => import('src/views/MarketsPage'))
//import MarketsPage from 'src/views/MarketsPage';
const Active = lazy(() => import('src/views/StockMovement/Active'))
// import Active from 'src/views/StockMovement/Active';
const Gainer = lazy(() => import('src/views/StockMovement/Gainers'))
//import Gainer from 'src/views/StockMovement/Gainers';
const Loser = lazy(() => import('src/views/StockMovement/Losers'))
//import Loser from 'src/views/StockMovement/Losers';
const StockOverview2 = lazy(() => import('src/views/StockOverview2'))
//import StockOverview2 from 'src/views/StockOverview2';
const ETFOverview = lazy(() => import('src/views/ETFOverview'))
//import ETFOverview from 'src/views/ETFOverview';
const CryptoOverview = lazy(() => import('src/views/CryptoOverview'))
//import CryptoOverview from 'src/views/CryptoOverview';
const MarketOverview = lazy(() => import('src/views/MarketOverview'))
// import MarketOverview from 'src/views/MarketOverview';
const DashboardView = lazy(() => import('src/views/reports/DashboardView'))
// import DashboardView from 'src/views/reports/DashboardView';
const NotFoundView = lazy(() => import('src/views/errors/NotFoundView'))
// import NotFoundView from 'src/views/errors/NotFoundView';
const ForgotPassword = lazy(() => import('src/views/SignIn/ForgotPassword'))
//import ForgotPassword from 'src/views/SignIn/ForgotPassword';
const MutualsListView = lazy(() => import('src/views/MutualFundsPage'))

const MutualFundsOverview = lazy(() => import('src/views/MutualFundsOverview'))


const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
     // { path: 'user', element: <PrivateRoute Component={AccountView}/> },
     // { path: 'account/:displayName', element: <Suspense fallback={<p> Loading... </p>}> <PrivateRoute Component={AccountView}  /> </Suspense> },
      { path: 'profile/:username', element: <Suspense fallback={<p> Loading... </p>}> <PrivateRoute Component={ProfileView}  /> </Suspense> },
      //{ path: 'customers', element: <CustomerListView /> },
      { path: 'stocks', element: <Suspense fallback={<p> Loading... </p>}>  <StocksListView /> </Suspense> },
      { path: 'crypto', element: <Suspense fallback={<p> Loading... </p>}> <CryptoPage /> </Suspense> },
      { path: 'etf', element: <Suspense fallback={<p> Loading... </p>}> <ETFListView /> </Suspense> },
      { path: 'search', element: <Suspense fallback={<p> Loading... </p>}> <SearchPage /> </Suspense> },
      { path: 'markets', element: <Suspense fallback={<p> Loading... </p>}> <MarketsPage /> </Suspense> },
      { path: 'mutual-funds', element: <Suspense fallback={<p> Loading... </p>}> <MutualsListView /> </Suspense> },
      { path: 'active', element: <Suspense fallback={<p> Loading... </p>}> <Active /> </Suspense> },
      { path: 'gainers', element: <Suspense fallback={<p> Loading... </p>}> <Gainer />  </Suspense> },
      { path: 'losers', element: <Suspense fallback={<p> Loading... </p>}> <Loser /> </Suspense> },
      // { path: 'info', element: <StockOverview /> },
      //{ path: 'info/:symb', element: <StockOverview /> },
      {path: 'info2/:symb', element: <Suspense fallback={<p> Loading... </p>}> <StockOverview2 /> </Suspense>},
      { path: 'etf/:symb', element: <Suspense fallback={<p> Loading... </p>}> <ETFOverview /> </Suspense> },
      { path: 'cryptooverview/:symb', element: <Suspense fallback={<p> Loading... </p>}> <CryptoOverview />  </Suspense>},
      { path: 'marketoverview/:symb', element: <Suspense fallback={<p> Loading... </p>}> <MarketOverview /> </Suspense> },
      { path: 'mutualfundoverview/:symb', element: <Suspense fallback={<p> Loading... </p>}> <MutualFundsOverview /> </Suspense> },
      { path: 'dashboard', element: <Suspense fallback={<p> Loading... </p>}> <DashboardView /> </Suspense> },
      //{ path: 'products', element: <ProductListView /> },
     // { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Suspense fallback={<p> Loading... </p>}> <Navigate to="/404" />  </Suspense>}
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Suspense fallback={<p> Loading... </p>}> <IsUserLoggedIn  Component={Login}/>  </Suspense>},
      { path: 'register', element: <Suspense fallback={<p>Loading...</p>}> <IsUserLoggedIn Component={Register}/>  </Suspense>    },
      { path: '404', element: <Suspense fallback={<p> Loading... </p>}> <NotFoundView /> </Suspense> },
      { path: 'forgot-password', element: <Suspense fallback={<p> Loading... </p>}>   <ForgotPassword/>  </Suspense>},
      { path: '/', element: <Suspense fallback={<p> Loading... </p>}> <Navigate to="/app/dashboard" /> </Suspense> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
