
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import FirebaseContext from './context/firebase';
import {firebase, FieldValue} from './lib/firebaselib';
import App from './App';


ReactDOM.render((
  <FirebaseContext.Provider value={{firebase, FieldValue}}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </FirebaseContext.Provider>
), document.getElementById('root'));

serviceWorker.unregister();
