import React from 'react';
import PatrolLogo from "../icons/Logos/Patrolsmall.png";
const Patrol = (props) => {
  return (
    <img
      alt="Investing Patrol Logo"
      src={PatrolLogo}
      {...props}
    />
  );
};

export default Patrol;