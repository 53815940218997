// pull out info from firebase
import { useState, useEffect, useContext } from 'react';
import UserContext from '../context/user';
import { getUserByUserId } from '../services/firebaseServices';

export default function useUser() {
  const [activeUser, setActiveUser] = useState({});
  const {user} = useContext(UserContext);

  useEffect(() => {
    async function getUserObjByUserId() {
      // function will call firebase service an get user data based on the ID.
      const [response] = await getUserByUserId(user.uid);
      setActiveUser(response);
    }

    if (user.uid) {
      getUserObjByUserId();
    }
  }, [user]);

  return { user: activeUser };
}