import React from 'react'
//import {Route, useNavigate} from 'react-router-dom'
import useAuthListener from '../hooks/use-auth-listener'
import { Navigate } from 'react-router-dom';
// import {useAuth} from '../src/views/Context/AuthContext'
//import AccountView from 'src/views/account/AccountView';
// import Login from 'src/views/SignIn/Login'

export default function IsUserLoggedIn({  Component}){
    const {user} = useAuthListener();
    //const navigate = useNavigate();

    return (
    user ? <Navigate to="/app/dashboard" />: <Component />
        )  
}


