import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import UserContext from '../../../context/user';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';

import {
  //AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  //Lock as LockIcon,
  //Settings as SettingsIcon,
  //ShoppingBag as ShoppingBagIcon,
  //User as UserIcon,
  //UserPlus as UserPlusIcon,
  //Users as UsersIcon
} from 'react-feather';

//import NewLogo from '../../../icons/Logos/medium.png';
import Patrol from '../../../icons/Logos/patrol.png';
import NavItem from './NavItem';
import ShowChartIcon from '@material-ui/icons/ShowChart'; // dashboard
import BlurCircularIcon from '@material-ui/icons/BlurCircular'; // crypto
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'; // etf
import TocIcon from '@material-ui/icons/Toc'; // markets
import BubbleChartIcon from '@material-ui/icons/BubbleChart'; // mutualfunds
import SearchIcon from '@material-ui/icons/Search'; // search
import TrendingUpIcon from '@material-ui/icons/TrendingUp'; // active
import TrendingDownIcon from '@material-ui/icons/TrendingDown'; // gainers
import HeightIcon from '@material-ui/icons/Height'; // losers
import AccountCircleIcon from '@material-ui/icons/AccountCircle'; // user profile


const user1 = {
  avatar: 'Patrol',
  jobTitle: '',
  name: 'Investing Patrol'
};

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  // {
  //   href: '/app/customers',
  //   icon: UsersIcon,
  //   title: 'Customers'
  // },
  {
    href: '/app/stocks',
    icon: ShowChartIcon,
    title: 'Stocks'
  },
  {
    href: '/app/crypto',
    icon: BlurCircularIcon,
    title: 'Crypto'
  },
  {
    href: '/app/mutual-funds',
    icon: BubbleChartIcon,
    title: 'Mutual Funds'
  },
  {
    href: '/app/etf',
    icon: MonetizationOnIcon,
    title: 'ETF'
  },
  {
    href: '/app/markets',
    icon: TocIcon,
    title: 'Markets'
  },
  {
    href: '/app/search',
    icon: SearchIcon,
    title: 'Search'
  },
  {
    href: '/app/active',
    icon: HeightIcon,
    title: 'Active'
  },
  {
    href: '/app/gainers',
    icon: TrendingUpIcon,
    title: 'Gainers'
  },
  {
    href: '/app/losers',
    icon: TrendingDownIcon,
    title: 'Losers'
  },
  // {
  //   href: `/app/profile/${user.displayName}`,
  //   icon: AccountCircleIcon,
  //   title: 'My Account'
  // },

  // {
  //   href: '/app/products',
  //   icon: ShoppingBagIcon,
  //   title: 'Products'
  // },
  // {
  //   href: '/app/account',
  //   icon: UserIcon,
  //   title: 'Account'
  // },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings'
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login'
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 70,
    height: 70,
    borderRadius: '10%',
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const {user} = useContext(UserContext);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={Patrol}
          to="/app/dashboard"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user1.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user1.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          {user && (
             <NavItem
             href={`/app/profile/${user.displayName}`}
             title={'My Profile'}
             icon={AccountCircleIcon}
             />
          )}
       
        </List>
      </Box>
      <Box flexGrow={1} />
      {/* <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      > */}
        {/* <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need more?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access 20 more screens
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            href="https://react-material-kit.devias.io"
            variant="contained"
          >
            See PRO version
          </Button> */}
        </Box>
     
    
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
