import React from 'react'
//import {Route, useNavigate} from 'react-router-dom'
import useAuthListener from './hooks/use-auth-listener'
// import {useAuth} from '../src/views/Context/AuthContext'
//import AccountView from 'src/views/account/AccountView';
import Login from 'src/views/SignIn/Login'

export default function PrivateRoute({  Component, ...rest}){
    const {user} = useAuthListener();
    return (
       user ?  <Component/> : <Login />
        )
}