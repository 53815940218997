import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import useAuthListener from './hooks/use-auth-listener';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import ReactGA from 'react-ga';
import UserContext from './context/user';

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    if(!window.GA_INITIALIZED){
      ReactGA.initialize('UA-193142899-1');
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname );
  }, [location])
}

const App = () => {
  const routing = useRoutes(routes);
  const {user} = useAuthListener();

  usePageViews();
  return (
    <UserContext.Provider value={{user}}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
    </UserContext.Provider>
    
  );
};

export default App;
