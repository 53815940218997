import React from "react";
import clsx from 'clsx';
import {
  Tooltip,
  Toolbar,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import {removeStock} from '../../services/firebaseServices'

// highlight:
// theme.palette.type === 'light'
// ? {
//     color: theme.palette.secondary.main,
//     backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//   }
// : {
//     color: theme.palette.text.primary,
//     backgroundColor: theme.palette.secondary.dark,
//   },


const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  title: {
    flex: '1 1 100%',
  },
}));



const ProfileStockToolbar = ({numSelected,  docId, selectedCustomerIds, setSelectedCustomerIds, userStocksInfo, setUserStocksInfo, setUserStocks}) => {
     const classes = useToolbarStyles();
    
   
    const handleDelete = (event) => {
        event.preventDefault();
       // console.log(userStocksInfo, selectedCustomerIds)
        getData3();
        removeStock(docId, selectedCustomerIds);
        setSelectedCustomerIds([])

    }
    
    // take userStocksInfo and filter it based on slectedCustomerIds
    
    const getData3 = () => {
      const items = userStocksInfo.map((poop) => poop.symbol)
      const valueToRemove = selectedCustomerIds
      const filteredItems = items.filter(item => !valueToRemove.includes(item))
       
      setUserStocks(filteredItems)
      //console.log('filtered Items', valueToRemove, items, filteredItems);
      fetch(
        `https://financialmodelingprep.com/api/v3/profile/${filteredItems}?apikey=${process.env.REACT_APP_FKEY}`
      ).then((res) =>
      res.json().then((data) => {
        if(!data.errors){
          setUserStocksInfo(data)
        } else {
          setUserStocksInfo([])
        }
      })
      )
    }
    
    // {
    //   [classes.highlight]: numSelected > 0, 
    // })}
    
     return (
       <Toolbar
         className={clsx(classes.root)}
       >
         {numSelected > 0 ? (
           <Typography  className={classes.title} color="inherit" variant="subtitle1" component="div">
             {numSelected} selected
           </Typography>
         ) : (
           <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
             Stocks
           </Typography>
         )}
   
         {numSelected > 0 ? (
           <Tooltip title="Delete Selected Stocks">
             <IconButton aria-label="Delete Selected Stocks">
               <DeleteIcon onClick={handleDelete}/>
             </IconButton>
           </Tooltip>
         ) : (
           <Tooltip title="No stocks Selected">
             <IconButton aria-label="No stocks Selected">
               <FilterListIcon />
             </IconButton>
           </Tooltip>
         )}
       </Toolbar>
     );
   };


export default ProfileStockToolbar;