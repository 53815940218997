import React, { useState, useContext } from 'react';
import FirebaseContext from '../../context/firebase';
//import UserContext from '../../context/user';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';



const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  exchangeStlye: {
    display: "flex"
  },
  exchangeSpace: {
    marginLeft: "10px"
  },
  buttonSpace:{
    marginLeft: "15px"
  }
}));


export default function AddCrypto({docId, userCryptos, setUserCryptos, setUserCryptosInfo, cryptoInput, className, ...rest}){
    
   const [userCrypto, setUserCrypto] = useState('');
   const isInvalid = userCrypto === '';
    const {firebase, FieldValue} = useContext(FirebaseContext)
    // user: {displayname}
    // const {
    //     user
    // } = useContext(UserContext)
    const classes = useStyles();
   // console.log('add stocks',userStocks);

    const handleSubmitComment = (event) => {
        event.preventDefault();
        //console.log('Old state', userCryptos)
        //console.log('New State', userCrypto.toLowerCase())
        setUserCryptos([...userCryptos, userCrypto]);
       
        
        //console.log('Combined State', userCrypto.split(" ").join("-"))
        let clean = userCrypto.toLowerCase().split(" ").join("-")
        //console.log("trying to do modifications", clean)
        //setUserCrypto(clean)
        //console.log("did mods work", userCrypto)
        getData4(clean)
    
        setUserCrypto('')
        return firebase
          .firestore()
          .collection('users')
          .doc(docId)
          .update({
            crypto: FieldValue.arrayUnion(clean)
          });

          
          
      };

      const getData4 = (clean) => {
        //console.log('Get data2 has been called. THis is my data', userStock)
        const test = [clean, ...userCryptos]
        //console.log('Combined State adding coins', test)
        setUserCryptos(test)
        fetch(
           `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${test}&order=market_cap_desc&per_page=100&page=1&sparkline=false`
         ).then((res) =>
         res.json().then((data) => {
           if(!data.errors){
             //console.log(data)
            setUserCryptosInfo(data)
           } else {
            setUserCryptosInfo([])
           }
         })
         )
       }



    return (
      <div
      className={clsx(classes.root, className)}
      {...rest}
      >
        <Box mt={3} >
        <Card>
          <CardContent>
            <Box maxWidth={500} className={classes.exchangeStlye}>
            <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                type="text"
                aria-label="Add a Coin by name"
                placeholder="Add A Crypto-Currency by name"
                value={userCrypto}
                onChange={({ target }) => setUserCrypto(target.value.toLowerCase().split(" ").join("-"))}
                inputRef={cryptoInput}
                />

        <Button className={classes.buttonSpace} variant="contained" color="primary"  disabled={isInvalid} onClick={handleSubmitComment}>Add New Coin</Button>

            </Box>
            </CardContent>
        </Card>
      </Box>
      </div>
    )
}