import React from "react";
import clsx from 'clsx';
import {
  Tooltip,
  Toolbar,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import {removeCrypto} from '../../services/firebaseServices'


const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  title: {
    flex: '1 1 100%',
  },
}));



const ProfileCryptoToolbar = ({numSelected, docId, selectedCustomerIds, setSelectedCustomerIds, userCryptosInfo, setUserCryptosInfo, setUserCryptos}) => {
     const classes = useToolbarStyles();
    
    const handleDelete = (event) => {
        event.preventDefault();
       //console.log('handle Delete', userCryptosInfo, selectedCustomerIds)
        getData5();
        removeCrypto(docId, selectedCustomerIds);
        setSelectedCustomerIds([])

    }
    
    // take userStocksInfo and filter it based on slectedCustomerIds
    
    const getData5 = () => {
      const items = userCryptosInfo.map((poop) => poop.id)
      const valueToRemove = selectedCustomerIds
      const filteredItems = items.filter(item => !valueToRemove.includes(item))
       
      setUserCryptos(filteredItems)
      //console.log('filtered Items', valueToRemove, items, filteredItems);
      fetch(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${filteredItems}&order=market_cap_desc&per_page=100&page=1&sparkline=false`
      ).then((res) =>
      res.json().then((data) => {
        if(!data.errors){
          //console.log(data)
          setUserCryptosInfo(data)
        } else {
          setUserCryptosInfo([])
        }
      })
      )
    }
    
    
    
     return (
       <Toolbar
         className={clsx(classes.root)}
       >
         {numSelected > 0 ? (
           <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
             {numSelected} selected
           </Typography>
         ) : (
           <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
             Coins
           </Typography>
         )}
   
         {numSelected > 0 ? (
           <Tooltip title="Delete">
             <IconButton aria-label="Delete Selected Stocks">
               <DeleteIcon onClick={handleDelete}/>
             </IconButton>
           </Tooltip>
         ) : (
           <Tooltip title="No Crypto Coins Selected">
             <IconButton aria-label="No Crypto Coins Selected">
               <FilterListIcon />
             </IconButton>
           </Tooltip>
         )}
       </Toolbar>
     );
   };


export default ProfileCryptoToolbar;