
import {firebase, FieldValue} from '../lib/firebaselib';

export async function doesUsernameExist(username) {
    const result = await firebase
    .firestore()
    .collection('users')
    .where('username', '==', username)
    .get();

    
    return result.docs.map((user) => user.data().length > 0);
}


export async function getUserByUsername(username) {
    const result = await firebase
    .firestore()
    .collection('users')
    .where('username', '==', username)
    .get();

    return result.docs.map((item) => ({
        ...item.data(),
        docId: item.id
    }));
}



    export async function getUserByUserId(userId) {
        const result = await firebase.firestore().collection('users').where('userId', '==', userId).get();
        const user = result.docs.map((item) => ({
            ...item.data(),
            docId: item.id
        }));

        return user;
    }

// could use 6:29:24 to avoid problem of trying to follow an already followed stock. In handleToggleLiked
  

  export async  function removeStock(docId, ticker){
      //console.log(docId, ticker)
    return firebase
    .firestore()
    .collection('users')
    .doc(docId)
    .update({
       stocks: FieldValue.arrayRemove(...ticker)
    })
}

export async  function removeCrypto(docId, ticker){
    //console.log(docId, ticker)
  return firebase
  .firestore()
  .collection('users')
  .doc(docId)
  .update({
     crypto: FieldValue.arrayRemove(...ticker)
  })
}

  // ? would this work .update({ ticker}) // Might have to make a function that checks if the user is already following the ticker to not add it again