import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
//import Select from "@material-ui/core/Select";
import FirebaseContext from '../../context/firebase';
// import UserContext from '../../context/user';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  exchangeStlye: {
    display: "flex"
  },
  exchangeSpace: {
    marginLeft: "10px"
  },
  buttonSpace:{
    marginLeft: "15px"
  }
}));




export default function AddStock({docId, userStocks, setUserStocks, commentInput,setUserStocksInfo,  className, ...rest}){
    
   const [userStock, setUserStock] = useState('');
   const isInvalid = userStock === '';
    const {firebase, FieldValue} = useContext(FirebaseContext)
    // const {
    //     user
    // } = useContext(UserContext)
    const classes = useStyles();
   // console.log('add stocks',userStocks);

   
   const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };


    const handleSubmitComment = (event) => {
        event.preventDefault();
       // console.log('Old state', userStocks)
       // console.log('New State', userStock)
       
        setUserStocks([...userStocks, userStock]);
        
       getData2()
        // console.log('This is called in submit',userStock)
        return firebase
          .firestore()
          .collection('users')
          .doc(docId)
          .update({
            stocks: FieldValue.arrayUnion(userStock.toUpperCase())
          });
      };

      const getData2 = () => {
        //console.log('Get data2 has been called. THis is my data', userStock)
        const test = [userStock.toUpperCase(), ...userStocks]
        // console.log('Combined State', test)
        setUserStocks(test)
        fetch(
           `https://financialmodelingprep.com/api/v3/profile/${test}?apikey=${process.env.REACT_APP_FKEY}`
         ).then((res) =>
         res.json().then((data) => {
           if(!data.errors){
             setUserStocksInfo(data)
           } else {
             setUserStocksInfo([])
           }
         })
         )
       }


//  onChange={({target}) => setUserStock(target.value.toUpperCase())}
    return (
      <div
      className={clsx(classes.root, className)}
      {...rest}
      >
        <Box mt={3} >
        <Card>
          <CardContent>
            <Box maxWidth={500} className={classes.exchangeStlye}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Add a stock by ticker"
                variant="outlined"
                onChange={({target}) => setUserStock(target.value.toUpperCase())}
                onKeyDown={handleKeyDown}
                value={userStock}
                aria-label="Add a stock by ticker"
                type="text"
                inputRef={commentInput}
              />

              
              <Button className={classes.buttonSpace} variant="contained" color="primary" disabled={isInvalid} onClick={handleSubmitComment}>Add New Stock</Button>
            </Box>
          
          </CardContent>
         
        </Card>
       
      </Box>
      </div>
        
    )

}


