import React, {useRef} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import useUser from '../../hooks/use-user';
import ProfileStocks from './ProfileStocks'
import ProfileCrypto from './ProfileCrypto';
import {
    Container,
    Grid,
    makeStyles
  } from '@material-ui/core';
  import Page from 'src/components/Page';
  import {getUserByUsername} from '../../services/firebaseServices'

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));


//if(user.length > 0){

export default function Profile(){
    const classes = useStyles();
    const commentInput = useRef(null);
    const cryptoInput = useRef(null);
    let {username} = useParams();
    const [user, setUser] = useState(null)
    //const [userExists, setUserExists] = useState(false);
    const navigate = useNavigate();
    
    

    useEffect(() => {
      async function checkUserExists(){
        const user = await getUserByUsername(username.toLowerCase());
       
        if(user[0].userId){
          setUser(user);
        // setUserExists(true)
      
        } else {
         navigate('/404'); // go to not found
      
        }
      }
        checkUserExists()
       
    }, [username, navigate])

    
    const {
        user: {docId,  stocks, crypto}
    } = useUser();
    

    // if(user){
    //   console.log( user)
    // userExists ?
    // }

    return  user? user[0].username.toLowerCase() ? (
        <Page
      className={classes.root}
      title="Dashboard View"
      name="description"
      description="Get a Comprehensive Source of Financial Data, Company Metrics, and Current prices on your favorite Stocks, Indexs, Crypto Currencies, and ETFs.  "
    >
        <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
        
        {stocks && (
        <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
        <ProfileStocks username={username} stocks={stocks} docId={docId} commentInput={commentInput}/>
        </Grid>
           )}

          {crypto && (    
        <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
        <ProfileCrypto username={username} crypto={crypto} docId={docId} cryptoInput={cryptoInput}/>
        </Grid>
          )} 

            </Grid>
        </Container>
    </Page>
    ) : null : null

}